

.remaining-cart-pre-order
    padding: 2rem 4rem
    @media screen and (max-width: 960px)
      padding: 1rem

.gallery
    display: flex
    justify-content: center
    padding 0 10rem 0 0
    margin-top: -2.2rem
    @media screen and (max-width: 960px)
      padding: 0
      display: flex
      justify-content: center
      width: 100%

.cart-order
  margin-top: -1rem

.text-back
  color $color-tertiary
  font-size: 1rem
  text-align: right
  cursor pointer
  display:  block
  font-weight: bold
  @media small
      display:  none
  @media medium
      display: none

.chanel-payment-grid
  display: grid
  gap: 16px
  grid-template-columns: repeat(2, 1fr)
  @media medium
     grid-template-columns: repeat(1, 1fr)
  @media small
     grid-template-columns: repeat(1, 1fr)

.hr-section
    margin-top 200px !important

.payment-label
    margin: 1rem 0
    padding: 0
    @media tablet
        padding: 0

::v-deep .btn-payment-layout
    padding: 0 !important

.checkout-action
   margin-top: 16px
   position relative
   display: flex
   justify-content: center
   .action
      display flex
      flex-direction: column
      gap: 16px
      width: 350px
   @media screen and (max-width: 768px)
    position: fixed
    width: 100%
    bottom: 0
    left: 0
    height: auto
    box-shadow: 1px 1px 2px 1px $color-gray-200;
    z-index 51
    margin-left: 0 !important
    margin-right: 0 !important
    padding-top: 1rem
    background: #fff
    .action
        width: 100%
        margin: 1.5rem 0
.full-h-screen
    height: 100vh

.margin-footer
    margin-bottom: 100px

.ref-text
    font-weight: bold
    color: $color-bnn-gray-medium-2
