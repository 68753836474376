
.summary-cart
  height: 120px;
  width: 100%
  bottom: 0
  left: 0
  position: fixed
  z-index 50
  background: #fff
  display: flex
  align-items: center
  box-shadow: 1px 1px 2px 1px $color-gray-200;
  @media tablet
    height: auto

@media tablet
  .--has-payment
      bottom: 0
  .--no-payment
      bottom: 0




.summary-cart-price-detail
    display: flex
    flex-direction: column
    // justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1 rem

.price-text-remaining
  color: $color-bnn

.text-discount
  color $color-bnn-red
