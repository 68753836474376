

.btn-payment-layout
    width 100%
    // padding 0 1rem
    @media medium
        width 100%
        // padding 0.5rem 0
    @media small
        width 100%
        // padding 0.5rem 0
.btn-payment
    width 100%
    background white
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    border-radius: 8px;
    height: auto;
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
        &:hover
          border-color: transparent;
          box-shadow: 0 0 0 2px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
	box-shadow: 0 0 0 2px $color-bnn;
	border-color: transparent;
     // box-shadow: 0px 2px 3px #00000029;
.btn-payment.has-error
     border-color: red;
