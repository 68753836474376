

.coupon-code
    display: flex
    justify-content: center
    align-items: center
    gap: 0 16px
    .input-coupon-code
        width:70%
    .use-code
        width: 30%

.bundle-list
    font-size: 0.95rem

.price-text
    font-size: 1.15rem
    color:  $color-text-price
    text-align: right

.price-bundle-text
    color:  $color-text-price
    text-align: right
    display: flex
    flex-direction: column
    justify-content: flex-start

.price-bundle-text.free
	color: #4CAF50;

.price-normal-text
    color:  $color-normal-price

.price-summary-text
    color:  $color-text-price

.payment-type-text
    color: $color-bnn-orange

.text-red
    color: $color-bnn-red-2

.sku-product
  display: flex
  gap 0 16px
  align-items: flex-start
  img
    width: 48px
    height: 48px
    object-fit: contain

.coupon-msg-error
  color: $color-bnn-red
  margin-top: -1rem
  font-size: 1rem

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: right

.product-item
  display: flex
  gap: 12px

.product-img {
  width: 48px
  height: 48px

  > img {
	width: 100%
	height: 100%
	object-fit: contain
  }
}
